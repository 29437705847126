import { Grid } from '@mui/material';
import Input from '../../../components/input.component';
import styles from './styles/settings-values.module.css';
import { defaultValuesForm } from '../utils/utils';
import CustomButton from '../../../components/button.component';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ISettingsParams } from '../interfaces/settings.interface';
import { setLoading } from '../../../store/modules/loading';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { IDefaultValues, setDefaultValues } from '../../../store/modules/default-values';
import { setAlert } from '../../../store/modules/snackbar';
import { useTranslation } from 'react-i18next';
import { useGetDefaultValues } from '../hooks/useGetDefaultValues';
import { useUserPermisions } from '../../../hooks/useUserPermissions';
import ImageInput from '../../../components/image-input.component';
import { useNavigate } from 'react-router-dom';
import TextAreaInput from '../../../components/text-area-input.component';
import VariablesHelper from './settings-email-variables.component';

const SettingsValues = () => {
	const [t] = useTranslation('global');
	const colors = useSelector((state: RootState) => state.colors);
	const defaultValues = useSelector((state: RootState) => state.defaultValues);
	const textareaRef = useRef<HTMLTextAreaElement>(null);

	const navigate = useNavigate();

	const { getDefaultValuesPermission, getStripePermission } = useUserPermisions();
	useGetDefaultValues();

	const dispatch = useDispatch();

	const reg = /^(?!.*\..*\.)[.\d]+$/;

	const [values, setValues] = useState<ISettingsParams>({
		partnerId: defaultValues.partnerId,
		language: defaultValues.language,
		default_iva: defaultValues.default_iva,
		default_charge_cost: defaultValues.default_charge_cost,
		default_charge_factor: defaultValues.default_charge_factor,
		default_parking_cost: defaultValues.default_parking_cost,
		default_parking_factor: defaultValues.default_parking_factor,
		paymentProcessorPercentaje: defaultValues.paymentProcessorPercentaje,
		paymentProcessorQty: defaultValues.paymentProcessorQty,
		default_email_txt: defaultValues.default_email_txt,
		default_email_img: defaultValues.default_email_img,
		themes: defaultValues.themes,
		stripe_key_prod: defaultValues.stripe_key_prod,
		stripe_key_qa: defaultValues.stripe_key_qa,
		publishable_key_prod: defaultValues.publishable_key_prod,
		publishable_key_qa: defaultValues.publishable_key_qa,
		account_id_prod: defaultValues.account_id_prod,
		account_id_qa: defaultValues.account_id_qa,
		app_minimum_hold: defaultValues.app_minimum_hold,
		url_qr: defaultValues.url_qr,
	});

	const [emailTitle, setEmailTitle] = useState<string>('');
	const [emailBody, setEmailBody] = useState<string>('');

	const disabledButton = useMemo((): boolean => {
		if (getDefaultValuesPermission() && !getStripePermission()) {
			return (
				values.default_iva.toString() === '' ||
				values.default_charge_cost.toString() === '' ||
				values.default_charge_factor.toString() === '' ||
				values.default_parking_cost.toString() === '' ||
				values.default_parking_factor.toString() === '' ||
				values.paymentProcessorQty?.toString() === '' ||
				values.paymentProcessorPercentaje?.toString() === '' ||
				emailTitle === '' ||
				emailBody === ''
			);
		} else if (!getDefaultValuesPermission() && getStripePermission()) {
			return (
				values.stripe_key_prod === '' ||
				values.stripe_key_qa === '' ||
				values.publishable_key_prod === '' ||
				values.publishable_key_qa === '' ||
				values.account_id_prod === '' ||
				values.account_id_qa === ''
			);
		} else if (getDefaultValuesPermission() && getStripePermission()) {
			return (
				values.default_iva.toString() === '' ||
				values.default_charge_cost.toString() === '' ||
				values.default_charge_factor.toString() === '' ||
				values.default_parking_cost.toString() === '' ||
				values.default_parking_factor.toString() === '' ||
				values.paymentProcessorQty?.toString() === '' ||
				values.paymentProcessorPercentaje?.toString() === '' ||
				emailTitle === '' ||
				emailBody === '' ||
				values.stripe_key_prod === '' ||
				values.stripe_key_qa === '' ||
				values.publishable_key_prod === '' ||
				values.publishable_key_qa === '' ||
				values.account_id_prod === '' ||
				values.account_id_qa === ''
			);
		} else {
			return true;
		}
	}, [values, emailTitle, emailBody]);

	const {
		execute: updateDefaultValues,
		value: valueDefaultValues,
		status: statusDefaultValues,
	} = useBackendRequest<ISettingsParams, IDefaultValues[]>({
		path: 'partners/',
		baseURL: process.env.REACT_APP_API,
		method: 'PUT',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: false }));
		if (statusDefaultValues === 'success') {
			if (valueDefaultValues?.data) {
				const newDefaultValues: IDefaultValues = valueDefaultValues.data[0];
				dispatch(setDefaultValues(newDefaultValues));
				dispatch(
					setAlert({
						show: true,
						message: t('default-values.update-msg'),
						severity: 'success',
					})
				);
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('default-values.update-error-msg'),
						severity: 'error',
					})
				);
			}
		} else if (statusDefaultValues === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('default-values.update-error-msg'),
					severity: 'error',
				})
			);
		}
	}, [statusDefaultValues]);

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		if (value !== '') if (!value.match(reg)) return;
		setValues({ ...values, [name]: value });
	};

	const handleSetUrlData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		if (value === '') {
			setValues({ ...values, [name]: null });
			return;
		}
		setValues({ ...values, [name]: value.trim() });
	};

	const handleStripeFields = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		setValues({ ...values, [name]: value });
	};

	const handleEmailFields = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { value } = event.target;
		setEmailTitle(value);
	};

	const handleEmailBody = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
		const { value } = event.target;
		setEmailBody(value);
	};

	const handleSaveValues = () => {
		dispatch(setLoading({ loading: true }));
		updateDefaultValues(
			{ ...values, default_email_txt: `${emailTitle}|${emailBody}` },
			defaultValues.id
		);
	};

	const getInputLabel = (inputName: string): string => {
		switch (inputName) {
			case 'default_charge_cost':
				return t('default-values.defaul-charging-cost');
			case 'default_charge_factor':
				return t('default-values.default-charging-factor');
			case 'app_minimum_hold':
				return t('default-values.app-minimum-hold');
			case 'url_qr':
				return t('default-values.url_qr');
			case 'default_parking_cost':
				return t('default-values.defaul-parking-cost');
			case 'default_parking_factor':
				return t('default-values.default-parking-factor');
			case 'paymentProcessorPercentaje':
				return t('common.payment-processor-per');
			case 'paymentProcessorQty':
				return t('common.payment-processor-qty');
			default:
				return 'Label';
		}
	};

	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) return;
		const file = event.target.files[0];

		const reader = new FileReader();
		reader.onloadend = function () {
			const base64String = reader.result!.toString().split(',')[1];
			setValues({ ...values, default_email_img: base64String });
		};

		if (file) {
			reader.readAsDataURL(file);
		}
	};

	const handleDeleteImage = () => {
		setValues({ ...values, default_email_img: null });
	};

	const handleInsertVariable = (variableText: string) => {
		if (!textareaRef.current || !getDefaultValuesPermission()) return;

		const textarea = textareaRef.current;
		const start = textarea.selectionStart;
		const end = textarea.selectionEnd;

		// Crear el nuevo texto con la variable insertada
		const updatedText =
			emailBody.substring(0, start) + variableText + emailBody.substring(end);

		// Llamar directamente a updateBody en lugar de crear un evento sintético
		setEmailBody(updatedText);

		// Reposicionar el cursor
		setTimeout(() => {
			textarea.focus();
			textarea.setSelectionRange(
				start + variableText.length,
				start + variableText.length
			);
		}, 0);
	};

	useEffect(() => {
		if (!getDefaultValuesPermission() && !getStripePermission()) {
			navigate('/settings/change-password');
		}
		const textToSplit = values.default_email_txt
			? values.default_email_txt.includes('|')
				? values.default_email_txt
				: 'Hello|This is your receipt'
			: 'Hello|This is your receipt';
		// Encuentra la posición del primer carácter |
		const separatorIndex = textToSplit.indexOf('|');
		const subject = textToSplit.substring(0, separatorIndex).trim();
		const body = textToSplit.substring(separatorIndex + 1).trim();
		setEmailTitle(subject);
		setEmailBody(body);
	}, []);

	return (
		<div className={styles.mainContainer}>
			{getStripePermission() && (
				<>
					<h3>Keys Values</h3>
					<Grid container spacing={2}>
						<Grid item sm={12} md={6}>
							<Input
								id={'stripe_key_prod'}
								name={'stripe_key_prod'}
								label={'Stripe key prod'}
								placeholder={t('common.required').toString()}
								style={{ height: '43px', paddingLeft: '14px' }}
								onChange={handleStripeFields}
								value={values.stripe_key_prod}
								type="text"
								required
							/>
						</Grid>
						<Grid item sm={12} md={6}>
							<Input
								id={'stripe_key_qa'}
								name={'stripe_key_qa'}
								label={'Stripe key QA'}
								placeholder={t('common.required').toString()}
								style={{ height: '43px', paddingLeft: '14px' }}
								onChange={handleStripeFields}
								value={values.stripe_key_qa}
								type="text"
								required
							/>
						</Grid>
						<Grid item sm={12} md={6}>
							<Input
								id={'publishable_key_prod'}
								name={'publishable_key_prod'}
								label={'Publishable key prod'}
								placeholder={t('common.required').toString()}
								style={{ height: '43px', paddingLeft: '14px' }}
								onChange={handleStripeFields}
								value={values.publishable_key_prod}
								type="text"
								required
							/>
						</Grid>
						<Grid item sm={12} md={6}>
							<Input
								id={'publishable_key_qa'}
								name={'publishable_key_qa'}
								label={'Publishable key QA'}
								placeholder={t('common.required').toString()}
								style={{ height: '43px', paddingLeft: '14px' }}
								onChange={handleStripeFields}
								value={values.publishable_key_qa}
								type="text"
								required
							/>
						</Grid>
						<Grid item sm={12} md={6}>
							<Input
								id={'account_id_prod'}
								name={'account_id_prod'}
								label={'Account ID prod'}
								placeholder={t('common.required').toString()}
								style={{ height: '43px', paddingLeft: '14px' }}
								onChange={handleStripeFields}
								value={values.account_id_prod}
								type="text"
								required
							/>
						</Grid>
						<Grid item sm={12} md={6}>
							<Input
								id={'account_id_qa'}
								name={'account_id_qa'}
								label={'Account ID QA'}
								placeholder={t('common.required').toString()}
								style={{ height: '43px', paddingLeft: '14px' }}
								onChange={handleStripeFields}
								value={values.account_id_qa}
								type="text"
								required
							/>
						</Grid>
					</Grid>
				</>
			)}
			{getDefaultValuesPermission() && (
				<>
					<h3>{t('default-values.set-msg')}</h3>
					<Grid container spacing={2}>
						<Grid item sm={12} md={12} marginBottom={1}>
							<Input
								id={'default_iva'}
								name={'default_iva'}
								label={t('default-values.vat').toString()}
								placeholder={t('common.required').toString()}
								style={{ height: '43px', paddingLeft: '14px' }}
								onChange={handleSetData}
								value={values.default_iva}
								type="text"
								required
								disabled={!getDefaultValuesPermission()}
							/>
						</Grid>
						<Grid item sm={12} md={12} marginBottom={5}>
							<Input
								id={'url_qr'}
								name={'url_qr'}
								label={t('default-values.url_qr').toString()}
								placeholder={t('common.required').toString()}
								style={{ height: '43px', paddingLeft: '14px' }}
								onChange={handleSetUrlData}
								value={values.url_qr}
								type="text"
								required
								disabled={!getDefaultValuesPermission()}
							/>
						</Grid>
						{defaultValuesForm.map((item) => (
							<Grid item key={item.name} sm={12} md={6}>
								<Input
									id={item.name}
									name={item.name}
									label={getInputLabel(item.name)}
									placeholder={t('common.required').toString()}
									style={{ height: '43px', paddingLeft: '14px' }}
									onChange={handleSetData}
									value={values[item.name as keyof ISettingsParams]!}
									type="text"
									required
									disabled={!getDefaultValuesPermission()}
								/>
							</Grid>
						))}
					</Grid>
					<h3 style={{ marginTop: '25px' }}>Email</h3>
					<Grid container spacing={2} margin={0}>
						<Grid item sm={12} md={12}>
							<Input
								id={'emailTitle'}
								name={'emailTitle'}
								label={'Email subject'}
								placeholder={t('common.required').toString()}
								style={{ height: '43px', paddingLeft: '14px' }}
								onChange={handleEmailFields}
								value={emailTitle}
								type="text"
								required
								tooltip={
									'Puedes usar variables pero no soporta modificadores'
								}
								disabled={!getDefaultValuesPermission()}
							/>
						</Grid>
						<Grid item sm={12} md={12} marginBottom={5}>
							<TextAreaInput
								id={'emailBody'}
								name={'emailBody'}
								label={'Email body'}
								placeholder={t('common.required').toString()}
								style={{ height: '43px', paddingLeft: '14px' }}
								onChange={handleEmailBody}
								value={emailBody}
								rows={10}
								notPaste={false}
								required
								ref={textareaRef}
								disabled={!getDefaultValuesPermission()}
							/>
							{getDefaultValuesPermission() && (
								<VariablesHelper
									onInsertVariable={handleInsertVariable}
								/>
							)}
						</Grid>

						<Grid item sm={12} md={12} marginBottom={5}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									width: 'fit-content',
									gap: '10px',
								}}
							>
								<h3 style={{ margin: 0, padding: 0 }}>Email image</h3>
								<ImageInput
									onChange={handleImageChange}
									onDelete={handleDeleteImage}
									image={values.default_email_img || ''}
								/>
							</div>
						</Grid>
					</Grid>
				</>
			)}
			{(getDefaultValuesPermission() || getStripePermission()) && (
				<CustomButton
					label={t('default-values.save')}
					variant={'solid'}
					onClick={handleSaveValues}
					styles={{
						width: '140px',
						backgroundColor: colors.accent,
						color: 'black',
						justifySelf: 'flex-end',
						alignSelf: 'flex-end',
						marginTop: 15,
					}}
					disabled={disabledButton}
				/>
			)}
			{!getDefaultValuesPermission() && !getStripePermission() && (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<h3>You don't have permissions assigned</h3>
				</div>
			)}
		</div>
	);
};

export default SettingsValues;
