import { TextareaHTMLAttributes, forwardRef } from 'react';
import { Label } from './label.component';
import { AlertInput } from './alert-input.component';
import { CustomTooltip } from './button-icon-small.component';

import style from './styles/input-styles.component.module.css';

interface ITextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	error?: { valid: boolean; msg: string };
	label?: string;
	name: string;
	notCopy?: boolean;
	notPaste?: boolean;
	containerStyles?: React.CSSProperties;
	tooltip?: string;
	rows?: number;
}

const TextAreaInput = forwardRef<HTMLTextAreaElement, ITextareaProps>(
	(
		{
			error,
			label,
			name,
			notCopy,
			notPaste,
			containerStyles,
			tooltip,
			rows = 5,
			...rest
		}: ITextareaProps,
		ref
	) => {
		const onCopy = (e: any) => {
			if (notCopy) {
				e.preventDefault();
				return false;
			}
		};

		const onPaste = (e: any) => {
			if (notPaste) {
				e.preventDefault();
				return false;
			}
		};

		return (
			<CustomTooltip title={tooltip} placement="top-start">
				<div
					className={`${style.content} ${
						rest.disabled ? style.contentDisabled : ''
					}`}
					style={containerStyles}
				>
					<Label
						label={label}
						name={name}
						required={rest.required}
						disabled={rest.disabled}
					/>
					<div className={style.inputContent}>
						<textarea
							{...rest}
							className={`${style.input} ${style.textarea}`}
							name={name}
							onCopy={onCopy}
							onPaste={onPaste}
							rows={rows}
							ref={ref}
						/>
					</div>
					{error && (
						<AlertInput name={name} valid={error.valid} message={error.msg} />
					)}
				</div>
			</CustomTooltip>
		);
	}
);

export default TextAreaInput;
