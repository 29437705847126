import { useState } from 'react';
import style from './styles/email-variables.module.css';

interface VariableItem {
	name: string;
	description: string;
}

interface ModifierItem {
	name: string;
	description: string;
}

interface VariablesHelperProps {
	onInsertVariable: (variable: string) => void;
}

export default function VariablesHelper({ onInsertVariable }: VariablesHelperProps) {
	const [isExpanded, setIsExpanded] = useState(false);

	// Available variables to use in the template
	const availableVariables: VariableItem[] = [
		{ name: 'consumed_kw', description: 'Consumed kilowatts' },
		{ name: 'total', description: 'Total charge amount' },
		{ name: 'station', description: 'Station name' },
		{ name: 'datetime_charging', description: 'Charging date and time' },
		{ name: 'load_time', description: 'Charging time' },
		{ name: 'order_number', description: 'Order number' },
	];

	// Modifiers that can be applied to variables
	const variableModifiers: ModifierItem[] = [
		{ name: 'currency', description: 'Format as currency ($XX.XX)' },
		{ name: 'date', description: 'Format as date (MM/DD/YYYY)' },
		{ name: 'time', description: 'Format as time (HH:MM:SS)' },
		{ name: 'datetime', description: 'Format as date and time' },
		{ name: 'toFixed(2)', description: 'Format number with 2 decimal places' },
		{ name: 'uppercase', description: 'Convert to UPPERCASE' },
		{ name: 'lowercase', description: 'Convert to lowercase' },
		{ name: 'capitalize', description: 'Capitalize The First Letter' },
	];

	const toggleExpanded = () => {
		setIsExpanded(!isExpanded);
	};

	const handleVariableClick = (variable: VariableItem) => {
		onInsertVariable(`{${variable.name}}`);
	};

	return (
		<div className={style.container}>
			<div className={style.header} onClick={toggleExpanded}>
				<span>Available Variables</span>
				<span
					className={`${style.arrow} ${isExpanded ? style.arrowExpanded : ''}`}
				>
					▼
				</span>
			</div>

			{isExpanded && (
				<div className={style.content}>
					<p className={style.description}>
						Click on a variable to insert it into the email body
					</p>

					<div className={style.variablesContainer}>
						{availableVariables.map((variable) => (
							<div
								key={variable.name}
								className={style.variableChip}
								title={variable.description}
								onClick={() => handleVariableClick(variable)}
							>
								{variable.name}
							</div>
						))}
					</div>

					<div className={style.divider}></div>

					<h4 className={style.subtitle}>Modifiers</h4>

					<p className={style.description}>
						You can add modifiers using the syntax: {'{variable|modifier}'}
					</p>

					<div className={style.variablesContainer}>
						{variableModifiers.map((modifier) => (
							<div
								key={modifier.name}
								className={style.modifierChip}
								title={modifier.description}
							>
								{modifier.name}
							</div>
						))}
					</div>

					<div className={style.examples}>
						<h4 className={style.subtitle}>Examples:</h4>
						<code>
							{'{total|currency}'} → $100.50
							<br />
							{'{datetime_charging|date}'} → 02/28/2025
							<br />
							{'{station|uppercase}'} → STATION NAME
							<br />
						</code>
					</div>
				</div>
			)}
		</div>
	);
}
