import { createSlice } from '@reduxjs/toolkit';

export interface IDefaultValues {
	id?: string;
	partnerId: number;
	language: string;
	default_iva: number;
	default_charge_cost: number;
	default_charge_factor: number;
	default_parking_cost: number;
	default_parking_factor: number;
	defaultThemeId?: string;
	paymentProcessorPercentaje: string | number;
	paymentProcessorQty: string | number;
	default_email_txt: string;
	default_email_img: string | null;
	themes: [];
	stripe_key_prod: string;
	stripe_key_qa: string;
	publishable_key_prod: string;
	publishable_key_qa: string;
	account_id_qa: string;
	account_id_prod: string;
	app_minimum_hold: number;
	url_qr: string;
}

const defaultValues: IDefaultValues = {
	partnerId: 0,
	language: '',
	default_iva: 0,
	default_charge_cost: 0,
	default_charge_factor: 0,
	default_parking_cost: 0,
	default_parking_factor: 0,
	defaultThemeId: '',
	paymentProcessorPercentaje: '',
	paymentProcessorQty: '',
	default_email_txt: '',
	default_email_img: '',
	themes: [],
	stripe_key_prod: '',
	stripe_key_qa: '',
	publishable_key_prod: '',
	publishable_key_qa: '',
	account_id_qa: '',
	account_id_prod: '',
	app_minimum_hold: 0,
	url_qr: '',
};

export const defaultValuesSlice = createSlice({
	name: 'utils',
	initialState: defaultValues,
	reducers: {
		setDefaultValues: (state, action) => ({ ...state, ...action.payload }),
	},
});

export const { setDefaultValues } = defaultValuesSlice.actions;

export default defaultValuesSlice.reducer;
