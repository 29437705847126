export const defaultValuesForm = [
	{
		label: 'Default charging cost',
		name: 'default_charge_cost',
	},
	{
		label: 'Default charging factor',
		name: 'default_charge_factor',
	},
	{
		label: 'Default parking cost',
		name: 'default_parking_cost',
	},
	{
		label: 'Default parinking factor',
		name: 'default_parking_factor',
	},
	{
		label: '',
		name: 'paymentProcessorPercentaje',
	},
	{
		label: '',
		name: 'paymentProcessorQty',
	},
	{
		label: 'Default minium hold',
		name: 'app_minimum_hold',
	},
];
